import { Row, Col } from 'antd'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from "react"
import Hero from '../components/Hero'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import 'antd/dist/antd.css';
import '../styles/global.scss';
import IndentedContent from '../components/helpers/IndentedContent';
import Container from '../components/Container';
import SectionEntry from '../components/SectionEntry';
import Feature from '../components/Feature';
import SectionEntryTitle from '../components/SectionEntryTitle'
import SectionEntryContent from '../components/SectionEntryContent'
import CenteredTextBlock from '../components/CenteredTextBlock'
import HeadlinerFeature from '../components/HeadlinerFeature'
import { Steps } from 'antd';
import { Button } from 'antd';
import { graphql, Link } from 'gatsby';
import BlogPost from '../components/BlogPost';
import { ArrowRightOutlined } from '@ant-design/icons'
import SEO from '../components/seo'

const { Step } = Steps;


// markup
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <BlogPost key={edge.node.id} post={edge.node} />)
    .slice(0, 1);

  return (
    <main>
      <SEO />

      <Container containerStyles={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, right: 0, height: '700px', backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
      </Container>

      <Container containerStyles={{ padding: 0 }}>
        <IndentedContent>
          <Header></Header>
        </IndentedContent>
      </Container>

      <Container>
        <IndentedContent>
          <Hero>
            <p>
              Mit petoffice hast du deinen Verein immer bei dir: Egal ob auf deinem Smartphone,
              Tablet oder auf dem PC, unterwegs oder im Büro.
              Vereinfache die Verwaltung in deinem Verein und konzentriere dich auf das, worauf es
              wirklich ankommt: Die Tiere.
            </p>
          </Hero>
        </IndentedContent>
      </Container>


      <Container>
        <IndentedContent>
          {/*<SectionEntry>
            <SectionEntryTitle>
              Neueste Änderungen
            </SectionEntryTitle>
            <SectionEntryContent>
              <p>
                Erfahre mehr über die neusten Entwicklungen von petoffice.
              </p>
            </SectionEntryContent>
  </SectionEntry>*/}

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="blog-teaser content--indented">
              <div className="blog-teaser__image">
                <StaticImage
                  src="../images/news-zur-vermittlungssoftware.svg" alt="News zur Vermittlungssoftware"
                  placeholder="tracedSVG"
                  layout="fixed"
                />
              </div>

              {Posts}
            </div>

            <div style={{ marginTop: '2rem' }}>
              <Link to="/posts/">
                <Button type="secondary">
                  Mehr Einträge im Blog
                  <ArrowRightOutlined />
                </Button>
              </Link>
            </div>
          </div>
        </IndentedContent>
      </Container>

      {/*
      <Container>
        <IndentedContent>
          <h3 style={{ textAlign: 'center' }}>
            Viele Erfolgsgeschichten: Was andere Vereine sagen
          </h3>

          <SuccessStories></SuccessStories>
        </IndentedContent>
      </Container>
*/}
      <Container>
        <IndentedContent>
          <SectionEntry>
            <SectionEntryTitle>
              Die Online-Komplettlösung<br />für Tierschutzvereine
            </SectionEntryTitle>
            <SectionEntryContent>
              <p>
                Tierschutzvereine haben mit viel Bürokratie zu kämpfen. Sei es die Pflege eines Bestandsbuchs,
                die Organisation von Vorkontrollen oder das Erstellen von Vertragsdokumenten.
                Wir geben dir die Werkzeuge an die Hand, um all das mit einem Fingerwisch zu erledigen. So hast du
                mehr Zeit, Tieren zu helfen.
              </p>
            </SectionEntryContent>
          </SectionEntry>

          <Row gutter={[32, 8]}>
            <Col span={24} md={{ span: 8 }}>
              <HeadlinerFeature
                image={<StaticImage
                  src="../images/datenbank.svg" alt="Stammdatenbank mit petoffice"
                  style={{ maxHeight: '300px' }}
                  placeholder="tracedSVG"
                  layout="fixed"
                />}
                title="Tierverwaltung"
              >
                Halte deinen Tierbestand organisiert und greife jederzeit auf alle wichtigen Informationen zu.
                Du siehst auf einen Blick, welche Tiere auf Pflegestelle sind und wer noch Kapazitäten frei hat. Erstelle
                Erinnerungen für die nächste Impfung und registriere deine Schützlinge im Tierregister von Tasso.
                Wir erstellen dir auch ein digitales Bestandsbuch, was den Anforderungen des §11 TierSchG genügt.
              </HeadlinerFeature>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <HeadlinerFeature
                image={<StaticImage
                  src="../images/vermittlung.svg" alt="Vermittlungen mit petoffice"
                  style={{ maxHeight: '300px' }}
                  placeholder="tracedSVG"
                  layout="fixed"
                />}
                title="Vermittlungshilfe"
              >
                Ob Anfragenverwaltung, Vertrags- und Rechnungserstellung oder Transportplanung: Wir helfen dir bei der
                Tiervermittlung mit Online-Fragebögen, VK-/NK-Organisation, Transportlisten und einem einheitlichen
                Vermittlungsprozess. Inseriere Tiere auf deiner Homepage oder in den sozialen Medien und lass
                Interessenten direkt online anfragen.
              </HeadlinerFeature>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <HeadlinerFeature
                image={<StaticImage
                  style={{ maxHeight: '300px' }}
                  src="../images/helfer.svg" alt="Verwaltung von Helfern mit petoffice"
                  placeholder="tracedSVG"
                  layout="fixed"
                />}
                title="Ehrenamtler und Wohltäter"
              >
                Behalte alle wichtigen Personen für deinen Verein im Überblick: Mitglieder, Tierpaten
                und Spender sowie Kontrolleure und Transporteure können übersichtlich verwaltet werden.
                Hinterlege Bilder und wichtige Dokumente, um sie jederzeit wieder zu finden.
              </HeadlinerFeature>
            </Col>
          </Row>

        </IndentedContent>
      </Container>

      <Container
        containerStyles={{ backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}
      >
        <IndentedContent>
          <SectionEntry>
            <SectionEntryTitle>Eine Lösung für viele Aufgaben</SectionEntryTitle>

            <SectionEntryContent>
              Wir setzen auf modernste Technologien, um dir und deinem Verein die Arbeit so stark
              wie möglich zu vereinfachen. Deine Daten sind rund um die Uhr auf jedem deiner Geräte verfügbar.
            </SectionEntryContent>
          </SectionEntry>

          <div className="features">
            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="200"
              data-sal-easing="ease-out">
              <Feature title="Erweiterbare Stammdatenbank">
                Behalte all deine Daten im Überblick. Wirklich alle. Denn wenn petoffice eine bestimmte Information nicht
                unterstützt, kannst du dir die notwendigen Felder ganz einfach selbst anlegen.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease-out">
              <Feature title="Medienverwaltung">
                Hinterlege beliebige Bilder und Dokumenten für Personen, Tiere oder Vermittlungen. Keine verwirrenden
                Ordnernamen mehr und du hast immer alles beisammen.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="400"
              data-sal-easing="ease-out">
              <Feature title="Dokumentenvorlagen">
                Erstelle beliebige Dokumente wie Verträge, Transportlisten oder Schutzgebühr-Rechnungen. Lade einfach
                deine Dokumente als Word-Dateien hoch und petoffice füllt alle Daten automatisch aus.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="500"
              data-sal-easing="ease-out">
              <Feature title="Vermittlung &amp; Transport">
                Erstelle und plane Transporte mit Datum und Ankunftsort und behalte so alle Vermittlungen im Blick.
                Wenn bei der Tierübergabe eines deiner Mitglieder anwesend ist, senden wir ihm am Vortag eine E-Mail mit allen nötigen Daten.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="600"
              data-sal-easing="ease-out">
              <Feature title="§11-konformes Bestandsbuch">
                Wir erstellen ein unveränderliches Bestandsbuch, was den Anforderungen aus Paragraph 11 TierSchG genügt. Das kannst
                du entweder als Excel-Datei exportieren oder du erstellst direkt einen Veterinäramtszugang in petoffice.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="700"
              data-sal-easing="ease-out">
              <Feature title="TASSO-Tierregister">
                Registriere Tiere automatisch im Tierregister von TASSO oder prüfe, ob sie bereits dort angemeldet sind. Wir informieren
                das Tierregister automatisch bei Änderung von wichtigen Tierdaten.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="800"
              data-sal-easing="ease-out">
              <Feature title="Digitales Vertragswerk">
                Übermittle Schutz- und Pflegeverträge sicher und schnell an deine Adoptanten - das spart Papier und schont die Umwelt.
                Akzeptierte Verträge werden unveränderbar an der Vermittlung hinterlegt, sodass du immer Zugriff darauf hast.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="900"
              data-sal-easing="ease-out">
              <Feature title="Vor- und Nachkontrollen">
                Bau dir einen Pool von zuverlässigen Kontrolleuren auf und wir helfen dir, daraus eine
                Person im Umkreis zu finden, die sich um Vorbesuche und Nachkontrollen kümmert. Keine Extra-Listen und hoffentlich
                irgendwann keine Facebook-Gruppen mehr.
              </Feature>
            </div>

            <div className="features__container" data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="1000"
              data-sal-easing="ease-out">
              <Feature title="Aufgabenverwaltung">
                Erstelle Aufgaben für dich und andere Vereinsmitglieder. Wir erinnern rechtzeitig an die Fälligkeit, sodass du
                dir nicht alles merken musst - egal ob Nachkontrolltermin oder die nächste Impfung.
              </Feature>
            </div>
          </div>
        </IndentedContent>
      </Container>


      <Container>
        <IndentedContent>
          <SectionEntry>
            <SectionEntryTitle>
              Roadmap
            </SectionEntryTitle>
            <SectionEntryContent>
              Nimm Teil an der Weiterentwicklung von petoffice
            </SectionEntryContent>
          </SectionEntry>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
            <Steps direction="vertical" size="small" current={1}>
              <Step title="Aktuelle Version" description="Unser neues Handbuch, Erstellen beliebiger Tierarten, Firmennamen" />
              <Step title="Nächste Version" description="Verbesserungen für eigene Ansichten und Zusatzfelder, Dauerpflegestellen" />
              <Step title="Zukünftig" description="Wir haben noch viel vor, sieh dir die Details an" />
            </Steps>

            <Link to="/roadmap/">
              <Button type="secondary">
                Roadmap ansehen
                <ArrowRightOutlined />
              </Button>
            </Link>
          </div>
        </IndentedContent>
      </Container>


      {/*

      <Container
        containerStyles={{ backgroundColor: '#EDF2F9', marginTop: '195px' }}
      >
        <IndentedContent styles={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: '-275px' }}>
            <StaticImage
              src="../images/cloud.png"
              placeholder="tracedSVG"
              alt="petoffice Cloud ist von überall aus erreichbar"
            />
          </div>

          <Row gutter={[32, 8]}>
            <Col span={24} md={{ span: 16 }}>
            <h2>Ein Ort - überall erreichbar</h2>

<p>
  Wir speichern deine Daten sicher auf Servern in Deutschland. Über dein Smartphone,
  Tablet oder den Webbrowser an deinem Rechner hast du von überall aus Zugriff auf
  alle Informationen.
</p>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <div style={{ position: 'absolute' }}>
              <StaticImage
              src="../images/petoffice-ipad.png"
              placeholder="tracedSVG"
              alt="petoffice auf einem iPad"
            />
              </div>
            </Col>
          </Row>

          


        </IndentedContent>
      </Container>
*/}
      <Container containerStyles={{ backgroundColor: '#f7f8fb', textAlign: 'center' }}>
        <IndentedContent>
          <SectionEntry>
            <SectionEntryTitle>
              Über petoffice
            </SectionEntryTitle>
            <SectionEntryContent>
              Mit all diesen Funktionen der modernen und cloud-basierten Software, die du von jedem Gerät überall auf der Welt erreichen kannst und jeder Menge Herz für Tiere, bietet petoffice das Komplettpaket für die Tiervermittlung.
            </SectionEntryContent>
          </SectionEntry>

          <div style={{ marginBottom: '6rem' }} className="content--indented">
            <CenteredTextBlock>
              <p>
                Von Anfang an wurde petoffice in Zusammenarbeit mit Tierschutzvereinen entwickelt, um Unterstützung zu leisten und um Prozesse zu optimieren.
              </p>
              <p>
                Besonders die zentrale Dokumentation aller wichtigen Informationen über Tiere, Adoptanten, Mitglieder, Pflegestellen, Transporteure und Tierschutzorganisationen sowie die Hinterlegung wichtiger Dokumente stehen hierbei im Vordergrund. Dabei wird auch die Zusammenarbeit mit dem Veterinäramt berücksichtigt und eine konforme Anlage des Bestandsbuches gewährleistet.
              </p>
              <p>
                Bei der Tiervermittlung werden alle Stammdaten automatisch angelegt und diese können jederzeit abgerufen werden. Kontrolleure, Transportmöglichkeiten und Pflegestellen werden übersichtlich dargestellt und können schnell gefunden werden. Insgesamt verhilft eine komplette Übersicht über den Vermittlungsprozess zu einem einfachen und reibungslosen Ablauf.
              </p>
              <p>
                Adoptanten bekommen zusätzlich die bequeme Möglichkeit, sich online, mit einfachen Formularen, für ein Tier zu bewerben und die vertraglichen Unterlagen zu prüfen. Außerdem werden Schutz- und Pflegeverträge automatisch erstellt, wobei ein einheitlicher und §11 TierSchG-konformer Vermittlungsprozess zu Stande kommt.
              </p>
              <p>
                Wir freuen uns, auch dich bei der Tierschutzarbeit unterstützen zu können.
              </p>
            </CenteredTextBlock>
          </div>

          <StaticImage
            src="../images/devices.png" alt="petoffice läuft auf all deinen Geräten"
            placeholder="tracedSVG"
          />
        </IndentedContent>
      </Container>

      <Footer></Footer>
    </main>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            slug
            title
          }
        }
      }
    }
  }
`