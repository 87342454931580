import { Button } from 'antd'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from "react"
import TenantCreation from './TenantCreation';
import './Hero.scss';
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'gatsby';

const Hero = (props) => {
  const [showTenantCreation, setShowTenantCreation] = React.useState(false);

  return (
    <div className="hero">
      <div className="hero__content">
        <h1 className="hero__headline">Dein Verein.<br />Immer und überall.</h1>

        <div className="hero__text">
          {props.children}
        </div>

        <div className="hero__buttons">
          <Button type="primary" onClick={() => setShowTenantCreation(true)}>Jetzt kostenlos testen</Button>
          {/*<Button type="secondary">Warum petoffice?</Button>*/}
          <Link to="/preise/">
              <Button type="secondary">
                Pakete und Preise
                <ArrowRightOutlined />
              </Button>
            </Link>
        </div>
      </div>

      <div className="hero__image">
        <StaticImage src="../images/devices.png" alt="petoffice läuft auf all deinen Geräten" placeholder="tracedSVG" />
      </div>

      <TenantCreation visible={showTenantCreation} onCancel={() => setShowTenantCreation(false)}  onOk={() => setShowTenantCreation(false)}></TenantCreation>
    </div>
  )
}

export default Hero;