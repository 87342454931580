import * as React from "react"

const CenteredTextBlock = (props) => {
  const containerStyles = {
    textAlign: 'center',
    fontSize: '0.875rem',
    padding: '0 32px',
  };

  return (
    <div style={containerStyles}>
      {props.children}
    </div>
  )
}

export default CenteredTextBlock;