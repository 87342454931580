import * as React from "react"

const HeadlinerFeature = (props) => {
  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',

    ...props?.containerStyles || {},
  };

  const textStyles = {
    fontSize: '0.875rem',
    paddingTop: '2rem',
  };

  return (
    <div style={containerStyles}
      data-sal="slide-up"
      data-sal-duration="750"
      data-sal-delay="50"
      data-sal-easing="ease-out">
      {props.image}

      <div style={textStyles}>
        <h3>{props.title}</h3>
        {props.children}
      </div>
    </div>
  )
}

export default HeadlinerFeature;